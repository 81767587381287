import {ActiveTrackLabelProps} from '@hconnect/uikit/src/lib2/components/rangeSliders/RangeSlider/Label'
import {Tooltip as MUIToltip, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface GetActiveTrackLabelParams {
  formatStep: (step: number) => string
  isTooltipShown: boolean
  stepsPerHour?: number
  precision?: number
}
export const getActiveTrackLabel = ({
  formatStep,
  isTooltipShown,
  stepsPerHour = 1,
  precision = 0
}: GetActiveTrackLabelParams): React.FC<ActiveTrackLabelProps> =>
  React.memo(({min, max}) => {
    const {t} = useTranslation()
    return (
      <MUIToltip
        open={isTooltipShown}
        sx={{
          padding: '0 0.5rem'
        }}
        leaveDelay={200}
        title={
          <Typography variant="body2">
            {t('common.hours', {number: ((max - min) / stepsPerHour).toFixed(precision)})}
          </Typography>
        }
      >
        <Typography
          style={{userSelect: 'none', paddingLeft: '0.5rem', paddingRight: '0.5rem'}}
          noWrap
          align="center"
          variant="subtitle1"
        >
          {[min, max]
            .map(formatStep)
            .filter((val, idx, self) => self.indexOf(val) === idx)
            .join(' - ')}
        </Typography>
      </MUIToltip>
    )
  })
