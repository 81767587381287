import {shortDayNameFormatter} from '@hconnect/uikit/src/common'
import moment from 'moment-timezone'
import {useMemo} from 'react'

import {TimeScaleFn} from '../../helpers/scale'
import {adjustEnLocale, getDayAndMonthWithTimezone, getPlantUtcOffset} from '../../selectors/time'

interface FormatStepParams {
  xScale: TimeScaleFn
  timezone: string
  convertStepToWidth: (step: number) => number
  language: string
}
interface ShortDayParams {
  withShortDay: boolean
}

export const getFormatStepGenerator =
  ({xScale, timezone, convertStepToWidth, language}: FormatStepParams) =>
  ({withShortDay}: ShortDayParams) =>
  (step: number) => {
    const currentDate = moment.utc(xScale.invert(convertStepToWidth(step)))
    const dayAndMonth = getDayAndMonthWithTimezone(currentDate, timezone, adjustEnLocale(language))

    if (!withShortDay) return dayAndMonth
    const offset = getPlantUtcOffset(timezone, currentDate)
    return `${shortDayNameFormatter(currentDate, language, offset)} ${dayAndMonth}`
  }

export const useFormatStep = ({
  xScale,
  timezone,
  convertStepToWidth,
  language
}: FormatStepParams) => {
  const formatStepGenerator = useMemo(
    () => getFormatStepGenerator({xScale, timezone, convertStepToWidth, language}),
    [xScale, timezone, convertStepToWidth, language]
  )

  const formatStepWithDay = useMemo(
    () => formatStepGenerator({withShortDay: true}),
    [formatStepGenerator]
  )
  const formatStepWithoutDay = useMemo(
    () => formatStepGenerator({withShortDay: false}),
    [formatStepGenerator]
  )
  return {formatStepWithDay, formatStepWithoutDay}
}
