import {dataTestId} from '@hconnect/uikit'
import {Box, Stack, useTheme} from '@mui/material'
import {throttle} from 'lodash'
import React, {useCallback, useLayoutEffect, useMemo, useRef} from 'react'

import {moveElementsHorizontally} from '../../../shared/components/ganttChart/chart.helpers'
import {useGanttChartCells} from '../../../shared/components/ganttChart/useGanttChartCells'
import {HCSlider} from '../../../shared/components/HCSlider'
import {useDateScale} from '../../../shared/helpers/scale'
import {calculateRangeWithinBoundary} from '../../../shared/helpers/utils'
import {useElementWidth} from '../../../shared/hooks/useElementWidth'
import {useMediaQueryBreakpoints} from '../../../shared/hooks/useMediaQueryBreakpoints'
import {usePlantConfig} from '../../../shared/hooks/usePlantConfigData'
import {useScrollDrag} from '../../../shared/hooks/useScrollDrag'
import type {AssetResponse} from '../../../shared/interfaces/api'
import {usePlanningChartStartEnd} from '../../page-planning/dailyPlanning/PlanningChartStartEndProvider'
import {StockLevelsCard} from '../../page-planning/dailyPlanning/stockLevels/StockLevelsCard'

import {OptimizerElectricityChartCard} from './OptimizerElectricityChartCard'
import {OptimizerPlanningChartCard} from './OptimizerPlanningChartCard'

interface OptimizerDetailsChartContainerProps {
  sortedAssets: AssetResponse[]
}

const _OptimizerDetailsChartContainer: React.FC<OptimizerDetailsChartContainerProps> = ({
  sortedAssets
}) => {
  const {spacing} = useTheme()
  const latest = usePlantConfig()
  const {sm: isMobile} = useMediaQueryBreakpoints()
  const {hoursRange, setHoursRange, hoursList, stepsPerHour, totalSteps, startOfPlan, endOfPlan} =
    usePlanningChartStartEnd()

  const [planningLayoutWrapperRef, planningLayoutWidth] = useElementWidth()
  const cardXPadding = parseInt(spacing(isMobile ? 2 : 3))
  const availableWidth = planningLayoutWidth - 2 * cardXPadding
  // constants for layout of the Gantt chart
  const labelColumnWidth = isMobile ? 100 : 150
  const dateLabelHeight = 24
  const hourlyPriceHeight = 30
  const cellHeight = 50
  const chartWidth = availableWidth === 0 ? 0 : availableWidth - labelColumnWidth
  const stepsPerDay = 24 * stepsPerHour
  const sliderMinSteps = stepsPerDay / 2

  const {updateGanttCells, cellWidth, cellsDisplayed} = useGanttChartCells({
    hoursDisplayed: hoursRange[1] - hoursRange[0],
    cellsPerHour: stepsPerHour,
    chartWidth
  })

  const ganttChartRef = useRef<HTMLDivElement>(null)
  const dragScrollWrapperRef = useRef<HTMLDivElement>(null)

  const [immediateHourOffset] = hoursRange

  const throttledUpdateGanttCells = useMemo(
    () => throttle(updateGanttCells, 100),
    [updateGanttCells]
  )

  // effect for the moving the charts
  useLayoutEffect(() => {
    moveElementsHorizontally(cellWidth * immediateHourOffset, [ganttChartRef])
  }, [cellWidth, immediateHourOffset])

  // Horizontal time scale
  const xScale = useDateScale({
    domain: [startOfPlan, endOfPlan],
    range: [0, cellWidth * totalSteps]
  })

  const updateHourRangeOnScrollOrDrag = useCallback(
    (delta: number) => {
      const currentOffset = immediateHourOffset + delta
      const newRange = calculateRangeWithinBoundary(
        [currentOffset, currentOffset + cellsDisplayed],
        [0, totalSteps]
      )
      setHoursRange(newRange)
    },
    [immediateHourOffset, cellsDisplayed, setHoursRange, totalSteps]
  )

  useScrollDrag({
    ref: dragScrollWrapperRef,
    callback: updateHourRangeOnScrollOrDrag,
    stepWidth: cellWidth,
    isDragEnabled: isMobile
  })

  return (
    <Box {...dataTestId('optimizer_details_chart_container')} ref={planningLayoutWrapperRef}>
      <Stack spacing={2}>
        <HCSlider
          timezone={latest.timezone_id}
          convertStepToWidth={(step) => step * cellWidth}
          xScale={xScale}
          label={{step: stepsPerDay}}
          minMax={[0, totalSteps]}
          stepsPerHour={stepsPerHour}
          roundClicks={{roundTo: 24 * stepsPerHour}}
          values={hoursRange}
          setValues={setHoursRange}
          stepLimits={{
            min: sliderMinSteps,
            max: totalSteps
          }}
          onChange={throttledUpdateGanttCells}
          onChangeComplete={updateGanttCells}
        />
        <StockLevelsCard
          startOfPlan={startOfPlan}
          endOfPlan={endOfPlan}
          cellWidth={cellWidth}
          totalSteps={totalSteps}
          immediateHourOffset={immediateHourOffset}
        />
        <Stack spacing={2} ref={dragScrollWrapperRef}>
          <OptimizerPlanningChartCard
            labelColumnWidth={labelColumnWidth}
            startOfPlan={startOfPlan}
            endOfPlan={endOfPlan}
            sortedAssets={sortedAssets}
            dateLabelHeight={dateLabelHeight}
            hourlyPriceHeight={hourlyPriceHeight}
            cellHeight={cellHeight}
            cellWidth={cellWidth}
            hoursDisplayed={cellsDisplayed}
            xScale={xScale}
            hoursList={hoursList}
            ref={ganttChartRef}
          />
          <OptimizerElectricityChartCard
            startOfPlan={startOfPlan}
            endOfPlan={endOfPlan}
            hoursList={hoursList}
            cellWidth={cellWidth}
            labelColumnWidth={labelColumnWidth}
            cellsDisplayed={cellsDisplayed}
          />
        </Stack>
      </Stack>
    </Box>
  )
}
export const OptimizerDetailsChartContainer = React.memo(_OptimizerDetailsChartContainer)
