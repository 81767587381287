import {MaterialStoragesHistory, MaterialType, Status} from '@hconnect/common/types'
import {Moment} from 'moment-timezone'
import {useCallback} from 'react'

import {useUrlParam} from '../../../hooks/useUrlParam'
import {
  getStoragesFromHistory,
  isHistoryStorageWithMaterialGuard
} from '../../../selectors/storages'
import {MomentRange} from '../../../selectors/time'
import {usePlannerQuery} from '../usePlannerQuery'

interface SelectorParams {
  storageHistory: MaterialStoragesHistory
  date: Moment
  materialType?: MaterialType
  hideDeleted: boolean
}

const getStorages = ({storageHistory, date, materialType, hideDeleted}: SelectorParams) => {
  const storagesFromHistory = getStoragesFromHistory(storageHistory, date)
  const storagesWithMaterial = storagesFromHistory
    .filter(isHistoryStorageWithMaterialGuard)
    .filter((storage) => (materialType ? storage.materialType === materialType : true))
    .filter((storage) => (hideDeleted ? storage.status !== Status.Deleted : true))

  return [...new Set(storagesWithMaterial?.map(({materialId}) => materialId) ?? [])]
}

interface StoredMaterialIdsQueryParams {
  materialType?: MaterialType
  timeFrame: MomentRange
  date?: Moment
  hideDeleted?: boolean
}

export const useStoredMaterialIdsFromHistoryQuery = ({
  materialType,
  timeFrame: [from, to],
  date,
  hideDeleted = true
}: StoredMaterialIdsQueryParams) => {
  const plantCode = useUrlParam('plantCode')

  const selector = useCallback(
    (data: MaterialStoragesHistory) =>
      getStorages({storageHistory: data, date: date ?? to, materialType, hideDeleted}),
    [date, to, materialType, hideDeleted]
  )
  return usePlannerQuery(
    'materialStorageHistory',
    [plantCode, from.toISOString(), to.toISOString()],
    {select: selector}
  )
}
