import {HistoryStorageWithMaterial, MaterialType, StorageWithMaterial} from '@hconnect/common/types'
import moment, {Moment} from 'moment-timezone'

import {LatestDemand} from '../interfaces/api'
import {DatetimeValue} from '../interfaces/common'

import {groupDemandByDay} from './demand'
import {groupBy} from './time'

type GetReachInDays = (params: {
  level: number
  dailyDemand: DatetimeValue[]
  deadStock: number
}) => number

/**
 * Computes how long the stock in a silo will last without new product.
 * 0 <= reach <= demand.length
 * @param level silo level
 * @param demand array of date-value pairs of the demand with 1 value per day!  If this demand is hourly or at another precision the calculation will be wrong
 * @returns reach
 */
export const getReachInDays: GetReachInDays = ({level, dailyDemand, deadStock}): number => {
  let reach = 0
  let adjustedLevel = level - deadStock
  if (adjustedLevel <= 0) return 0
  for (const demand of dailyDemand) {
    adjustedLevel -= demand.value
    if (adjustedLevel < 0) {
      // calculate partial day depletion
      reach += (adjustedLevel + demand.value) / demand.value
      break
    }
    reach += 1
  }
  return reach
}

export interface MaterialInfo {
  materialId: string
  materialType: MaterialType
  capacity: number
  level: number
  demandToday: number
  reach?: number
  maxReach?: number
  reachDate?: Moment
  storages: HistoryStorageWithMaterial[]
}

const getStartOfDay = (datetime: string, timezoneId: string): string =>
  moment.utc(datetime).tz(timezoneId).startOf('day').toISOString()

interface GetStorageToMaterialAmountParams {
  storagesWithMaterial: StorageWithMaterial[]
  createdAt: string
  timezoneId: string
  demand: LatestDemand
}
/**
 * assembles data as needed by the Stock Page
 */
export const getStorageToMaterialAmount = ({
  storagesWithMaterial,
  createdAt,
  timezoneId,
  demand
}: GetStorageToMaterialAmountParams): MaterialInfo[] => {
  const materialDetails: Record<string, Omit<MaterialInfo, 'materialId'> & {deadStock: number}> = {}

  const tomorrow = moment.utc(createdAt).tz(timezoneId).startOf('day').add(1, 'day')

  for (const storage of storagesWithMaterial) {
    if (!(storage.materialId in materialDetails)) {
      const forecast = groupDemandByDay(demand[storage.materialId]?.forecast, timezoneId)
      materialDetails[storage.materialId] = {
        capacity: 0,
        level: 0,
        storages: [],
        deadStock: 0,
        demandToday:
          forecast.find((v) => moment.utc(v.datetime).tz(timezoneId).isSame(tomorrow, 'day'))
            ?.value ?? 0,
        materialType: storage.materialType
      }
    }
    materialDetails[storage.materialId].level += storage.currentStorageLevel.level
    materialDetails[storage.materialId].deadStock += storage.deadStock
    materialDetails[storage.materialId].capacity += storage.capacity
    materialDetails[storage.materialId].storages.push({...storage})
  }

  for (const [materialId, data] of Object.entries(materialDetails)) {
    const forecast = groupBy(demand[materialId]?.forecast ?? [], (datetime) =>
      getStartOfDay(datetime, timezoneId)
    )
    if (forecast.length > 0) {
      data.reach = getReachInDays({
        level: data.level,
        dailyDemand: forecast,
        deadStock: data.deadStock
      })
      data.maxReach = forecast.length
      const lastReachedDay = Math.floor(data.reach)
      data.reachDate =
        forecast.length > lastReachedDay
          ? moment.utc(forecast[lastReachedDay].datetime)
          : moment.utc(forecast[forecast.length - 1].datetime).add(1, 'day')
    }
  }

  // return sorted material list
  return Object.keys(materialDetails).map((k) => ({
    materialId: k,
    ...materialDetails[k]
  }))
}
