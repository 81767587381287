import {RangeSlider, RangeSliderProps} from '@hconnect/uikit/src/lib2/components/rangeSliders'
import {debounce} from 'lodash'
import React, {useRef, useState, useMemo} from 'react'
import {unstable_batchedUpdates} from 'react-dom'
import {useTranslation} from 'react-i18next'

import {TimeScaleFn} from '../../helpers/scale'

import {useFormatStep} from './GetFormatStepGenerator'
import {getActiveTrackLabel} from './HCSliderActiveTrack'

type PickRequiredOptional<T, R extends keyof T, O extends keyof T> = Pick<Required<T>, R> &
  Pick<Partial<T>, O>

interface HCSliderProps
  extends PickRequiredOptional<
    RangeSliderProps,
    'values' | 'setValues' | 'stepLimits' | 'minMax' | 'onChangeComplete',
    'roundClicks'
  > {
  onChange?: (values: [number, number]) => void
  timezone: string
  convertStepToWidth: (step: number) => number
  stepsPerHour?: number
  tooltipPrecision?: number
  xScale: TimeScaleFn
  label: RangeSliderProps['label']
}
export const HCSlider: React.FC<HCSliderProps> = React.memo(
  ({
    minMax,
    roundClicks,
    timezone,
    onChangeComplete,
    convertStepToWidth,
    stepsPerHour,
    tooltipPrecision,
    label,
    xScale,
    values,
    setValues,
    onChange,
    stepLimits
  }) => {
    const changingRef = useRef<boolean>(false)
    const hoverRef = useRef<boolean>(false)

    const [isTooltipShown, setIsTooltip] = useState(false)

    // we need this to avoid the warning case when full page is rerendered during check tooltip call
    const debouncedSetIsTooltip = useMemo(
      () => debounce((shouldShow: boolean) => setIsTooltip(shouldShow)),
      []
    )

    const checkTooltip = () => {
      const shouldShow = changingRef.current || hoverRef.current
      if (shouldShow !== isTooltipShown) debouncedSetIsTooltip(shouldShow)
    }

    const {
      i18n: {language}
    } = useTranslation()

    const {formatStepWithDay, formatStepWithoutDay} = useFormatStep({
      xScale,
      convertStepToWidth,
      timezone,
      language
    })

    const ActiveTrack = useMemo(
      () =>
        getActiveTrackLabel({
          stepsPerHour,
          formatStep: formatStepWithDay,
          isTooltipShown,
          precision: tooltipPrecision
        }),
      [stepsPerHour, isTooltipShown, formatStepWithDay, tooltipPrecision]
    )

    return (
      <RangeSlider
        isDraggable={true}
        label={
          label && {
            centerLabel: false,
            formatFn: formatStepWithoutDay,
            ...label
          }
        }
        minMax={minMax}
        step={1}
        ActiveTrackLabel={ActiveTrack}
        roundClicks={roundClicks}
        onTrackHover={(value) => {
          hoverRef.current = value
          checkTooltip()
        }}
        onChangeComplete={(values) => {
          changingRef.current = false
          unstable_batchedUpdates(() => {
            checkTooltip()
            onChangeComplete(values)
          })
        }}
        values={values}
        setValues={setValues}
        onChange={(values) => {
          changingRef.current = true
          unstable_batchedUpdates(() => {
            checkTooltip()
            onChange?.(values)
          })
        }}
        stepLimits={stepLimits}
      />
    )
  }
)

HCSlider.displayName = 'HCSlider'
